.logo{
    float: left;
    margin-right: 6%;
}
.nav-bar{
    float: right;
}

.phone-sticky-left {
    position: fixed;
    top: 92%;
    z-index: 20;
    padding: 0px 25px;
}

.phone-sticky-right {
    position: fixed;
    top: 92%;
    right: 0;
    z-index: 20;
    padding: 0px 25px;
}

.sticky-img {
    height: 50px;
}

@media screen and (max-width: 450px) {
    .sticky-img {
        height: 40px;
    }
}