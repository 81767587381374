.top-bar {
    background-color: #5a6e7e;
    padding: 2px 0px;
}

.top-bar ul {
    margin-bottom: 0;
    padding-right: 5% !important;
}

.TopFlex {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
}

.TopBarButton{
    color: #fff;
    text-decoration: none;
    margin-left: 44px;
    font-size: 13px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-transform: none;
}

.TopBarButton:hover{
    color: #fff;
    text-decoration: none;
    margin-left: 44px;
    font-size: 13px;
}

.TopBarImage {
    width: 17px;
    height: 17px;
    margin-right: 7px;
}

@media screen and (max-width: 450px) {
    .top-bar{
        height: 3vh;
    }
    .TopFlex {
        display: none;
    }
}