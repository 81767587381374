@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body{
  font-family: 'Roboto', sans-serif;
  background-color: #fff;
  height: 100vh;
}

/* /* width
::-webkit-scrollbar {
  width: 10px;
} */

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #5a6e7e; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5a6e7e; 
}

.container {
  max-width: 95%;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.container-flud{
  max-width: 95%;
  margin: auto;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

a {
  text-decoration: none;
  color: #000;
  text-transform: capitalize;
}

ul {
  list-style-type: none;
}