.mobile {
    display: none;
}

@media screen and (max-width: 900px) {
   .mobile{
       display: block;
   }
   .web {
    display: none;
   }   
}

@media screen and (max-width: 450px) {
    .carousel-indicators {
        display: none !important;
    }
    .hero {
        background-position: center; /* Center the image */
        background-repeat: no-repeat; /* Do not repeat the image */
        background-size: cover;
    }
    
    .carousel-control-prev-icon, 
    .carousel-control-next-icon {
        display: none !important;
    }
}