.UrunContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.UrunTitleContainer {
    width: 25%;
    height: 7.2rem;
    border-right: 1px solid #5a6e7e;
    display: flex;
    align-items: center;
}

.UrunTitle {
    color: #5a6e7e;
    font-weight: 600;
    font-size: 25px;
}

.UrunButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 75%;
}

.UrunButton {
    width: 200px;
    height: 7.2rem;
    border-right: 1px solid #5a6e7e;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.UrunButton a {
    text-align: center;
    text-decoration: none;
    color: black;
}

.UrunButton a:hover {
    color: black;
}

.UrunButton:hover {
    border-bottom: 15px solid #5a6e7e;
}

.UrunText {
    font-size: 12px;
    margin-top: 6px;
    margin-bottom: 0;
}

.UrunButtonImage {
    height: 50px;
}

/* .shake{
    animation: shake 1s infinite;
}
@keyframes shake {
  0%  { transform: translate(2px, 1px)   rotate(0deg);  }
  10% { transform: translate(-1px, -2px) rotate(-2deg); }
  20% { transform: translate(-3px, 0px)  rotate(3deg);  }
  30% { transform: translate(0px, 2px)   rotate(0deg);  }
  40% { transform: translate(1px, -1px)  rotate(1deg);  }
  50% { transform: translate(-1px, 2px)  rotate(-1deg); }
  60% { transform: translate(-3px, 1px)  rotate(0deg);  }
  70% { transform: translate(2px, 1px)   rotate(-2deg); }
  80% { transform: translate(-1px, -1px) rotate(4deg);  }
  90% { transform: translate(2px, 2px)   rotate(0deg);  }
  100%{ transform: translate(1px, -2px)  rotate(-1deg); }
}
 */
@media screen and ( max-width: 1400px) {
    .UrunTitle {
        font-size: 20px;
    }
}

@media screen and ( max-width: 1200px) {
    .UrunTitle {
        font-size: 16px;
    }
}

@media screen and ( max-width: 1080px ) {
    .UrunTitleContainer {
        display: none;
    }
    .UrunButtonContainer {
        width: 100%;
    }
    .UrunButton span {
        display: none;
    }
    .UrunButton a {
        padding: 0px 0 0;
    }
}

@media screen and ( max-width: 850px ) {
    .UrunButtonImage {
        height: 40px;
    }
    .UrunButton {
        height: 7.2rem;
    }
    .UrunButton {
        border-right:0px !important;
    }
    .resUrunButton {
        border-right: 1px solid #5a6e7e !important;
    }
}

@media screen and ( max-width: 450px ) {
    .UrunButtonImage {
        height: 35px;
    }
    .UrunButton {
        height: 5.2rem;
    }
    .UrunButton {
        border-right:0px !important;
    }
    .resUrunButton {
        border-right: 1px solid #5a6e7e !important;
    }
}