.BilgiContainer {
    background-color: #EBEBEB;
}
.BilmenizGerekenBox {
    background-color: #fff;
    border-radius: 15px;
    padding: 15px 10px;
    width: 245px !important;
    margin-bottom: 30px;
    height: 319px;
}

.BilmenizGerekenBoxImageBox {
    width: 225px;
    height: 125px;
}

.BilmenizGerekenBoxImage {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #707070;
}

.BilmenizGerekenBoxTitle {
    font-size: 15px;
    font-weight: bold;
    margin-top: 8px;
}

.BilmenizGerekenBoxText {
    font-size: 13px;
}

.BilmenizGerekenBoxButton {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: end;
    font-size: 13px;
    position: fixed;
    bottom: 40px;
    margin-left: 160px;
}

@media screen and (max-width: 450px) {
    .slick-prev:before, .slick-next:before {
        display: none !important;
    }
}