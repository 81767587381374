.saglik-banner {
    background-image: url('../../images/saglik.jpg');
    height: 270px;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px 0;
}

.saglik-banner-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.saglik-banner-content h1 {
    color: #000;
}