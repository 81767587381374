.BannerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 25px 0;
}

.BannerTitleBoxText{
    font-size: 23px;
    font-weight: bold;
    margin-bottom: 6px;
}

.BannerContentBox {
    width: 67%;
}

.BannerContentBoxText{
    font-size: 15px;
    margin-bottom: 0;
}

.BannerContentBoxButton{
    font-size: 13px;
    text-decoration: none;

}

.BannerButtonBox {
    display: flex;
    color: rgb(0, 0, 0);
    text-decoration: none;
    text-transform: none;
}

.BannerButtonBoxText {
    font-weight: bold;
    margin-bottom: 0;
    font-size: 23px;
    margin-left: 10px;
}

@media screen and (max-width: 850px) {
    .BannerContentBox {
        width: 55%;
    }
}

@media screen and (max-width: 450px) {
    .BannerContentBox,
    .BannerContentBoxButton,
    .BannerContentBoxText {
        display: none;
    }

    .BannerTitleBoxText {
        font-size: 1.1rem;
    }
}