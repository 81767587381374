
.about-flex,
.profil-flex{
    display: flex;
    justify-content: flex-start;
    align-self: flex-start;
    flex-wrap: wrap;
}

.info-menu {
    width: 350px;
    padding: 30px 0px;
}

.profil-menu {
    width: 250px;
    padding: 30px 0px;
}

.about-menu {
    width: 300px;
    height: 520px;
    border-radius: 20px;
    margin: 15px 0;
    background-image: url('../images/bg2.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.about-menu .turkhavak-logo {
    height: 35px;
    margin-top: 70%;
    margin-left: 20px;
}

.info-menu  .profil-menu-box,
.profil-menu .profil-menu-box {
    width: 100%;
    padding: 10px 0 0;
    border: 1px solid #5a6e7e;
    border-radius: 20px;
}

.profil-menu-box .profil-menu-header {
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profil-menu-header h4 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
}

.profil-menu-header .profil-menu-circle {
    width: 80px;
    height: 80px;

}

.profil-menu-box .profil-menu-content {
    padding: 0;
}

.info-menu .profil-menu-content li {
    height: 40px;
    cursor: pointer;
}

.profil-menu-content li {
    height: 30px;
    cursor: pointer;
}

.profil-menu-content .isActive {
    background: none;
    padding: 0;
    margin: 0;
    font-weight: 600;
}

.info-content {
    width: 760px;
    padding: 30px 50px;
}

.profil-content {
    width: 860px;
    padding: 30px 50px;
}

.about-content {
    width: 810px;
    padding: 30px 50px;
}

.about-content-box h3 {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 20px;
}

.about-content-box p  {
    font-size: 15px;
}

.about-content-box ul li {
    font-size: 15px;
}

.profil-content-box h2 {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 20px;
}

.profil-content-box .active {
    display: block;
}

.profil-content-box .inactive {
    display: none;
}

/*--------------------------- BİLGİLERİM ---------------------------------*/

.profil-bilgi-box {
    border: 1px solid #707070;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 15px;
    margin-bottom: 10px;
}

.profil-bilgi-box .title-box {
    width: 150px;
}

.profil-bilgi-box .text-box {
    width: 500px;
}

.profil-bilgi-box .text-box p {
    margin-bottom: 0;
    font-weight: 600;
}

.profil-bilgi-box .button-box {
    width: 50px;
}

.input-box {
    width: 95%;
    height: 45px;
    margin: 0px;
    border: 0;
    padding: 5px;
}

.profil-bilgi-box .button-box .button {
    text-decoration: underline;
    color: #0A4CAC;
}

/*--------------------------- POLİÇELERİM ---------------------------------*/

.profil-police-box {
    border: 1px solid #707070;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px 15px;
    margin-bottom: 10px;
}

.profil-police-box .police-column {
    width: 200px;
    margin: 0 10px;
    justify-content: space-between;
}

.police-column .police-title-box {
    margin-bottom: 5px;
    margin-top: 10px;
}

.police-column .police-title-box label {
    font-weight: 600;
    font-size: 15px;
}

.police-column .police-title-box .line{
    width: 100%;
    height: 1px;
    background-color: #707070;
}

.police-column .police-img {
    height: 29px;
}

.police-column p {
    font-size: 12px;
    margin-bottom: 0px;
}

.police-column .police-button-box {
    margin-top: 10px;
}

.police-column .police-button a,
.police-column .police-button {
    padding: 0 0;
    background-color: #0A4CAC;
    border-radius: 50px;
    margin-bottom: 5px;
    text-align: center;
    color: #fff;
    font-size: 15px;
    cursor: pointer;
    text-decoration: none;
    max-width: 200px;
    text-align: -webkit-center;
}

.button-center {
    text-align: -webkit-center;
}

.teklif-img {
    height: 35px;
    margin-right: 20px;
}

.sigortaSepeti .SepetContainer {
    justify-content: flex-start;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 25px;
}

.sigortaSepeti .SepetContainer .teklif-box{
    width: 240px;
}



@media screen and (max-width: 1200px) {
    .profil-content {
        padding: 30px 0;
    }
    .profil-police-box .police-column {
        width: 95%;
    }

}

@media screen and (max-width: 450px) {
    .profil-menu {
        width: 100%;
    }
    .profil-content {
        width: 100%;
        padding: 0;
    }
    .profil-police-box,
    .profil-bilgi-box {
        flex-wrap: wrap;
    }
    .profil-police-box{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .police-column p {
        margin-top: 10px !important;
    }
    .sigortaSepeti .SepetContainer{
        justify-content: center;
    }
}