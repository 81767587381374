footer {
    background-color: #EBEBEB;
    padding: 15px 0px;
}

.footer-flex {
    display: flex;
    flex-direction: row;
}

.FooterContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.FooterLine {
    width: 2px;
    height: auto;
    background-color: white;
}

.FooterRow {
    width: 100%;
    height: 2px;
    background-color: white;
    display: none;
    margin: 10px 0;
}

.FooterImage {
    height: 35px;
}

.FooterButtonImg {
    height: 25px;
}

.FooterBox {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px 0;
}

.FooterButton {
    margin-top: 7px;
    text-decoration: none;
    color: black;
    font-size: 12px;
}

.footer-bottom {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    font-size: 12px;
    font-weight: 700;
}

.FooterBottomImage {
    margin-left: 15px;
    margin-right: 15px;
    height: 30px;
}

.footer-icon {
    height: 20px;
    margin-right: 10px;
}

.footer-iletisim {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
}

.footer-iletisim .address {
    width: 220px;
    margin-top: 0 !important;
}

.footer-iletisim-licon-box {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.footer-iletisim-line {
    width: 2px;
    height: 100%;
    background-color: #000;
    margin: 0 10px 0 0;
}

@media screen and (max-width: 1200px) {
    .FooterLine {
        width: 2px;
        height: auto;
        background-color: white;
    }
}

@media screen and (max-width: 850px) and (min-width: 451px) {
    .FooterBox h5 {
        font-size: 15px;
        font-weight: 500;
    }
    .FooterBox a {
        font-size: 11px;
    }
    .FooterImage {
        height: 25px;
    }
}

@media screen and (max-width: 450px) {
    .FooterLine {
        display: none;
    }
    .FooterRow{
        display: block;
    }

    .FooterBox {
        width: 100%;
        text-align: center;
        align-items: center;
    }
    .footer-bottom {
        font-size: 10px;
    }
    .FooterBox h5 {
        font-size: 17px;
        font-weight: 600;
    }
    .footer-iletisim {
        justify-content: center;
    }
}

@media screen and (max-width: 290px) {
    .footer-bottom {
        font-size: 8px;
    }
    .FooterBottomImage {
        height: 18px;
    }
}