.kasko-banner {
    background-image: url('../../images/kaskobg.png');
    height: 270px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 80px 0;
}

.kasko-banner-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.kasko-banner-content h1 {
    color: #fff;
}

/*--------------------------------------- Kasko Bilgi Container ---------------------------------------*/

.teklif-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.teklif-img-text {
    position: absolute;
    bottom: 25%;
    left: 25%;
}

.teklif-img-text h2 {
    color: #fff;
    font-weight: 600;
    font-size: 45px;
}

.grupamaOzelImg {
    height: 31px;
}

.tooltip-inner {
    background-color: #f44337 !important;
}

.container-fluid {
    padding: 0px !important;
}

.teklif-image-content {
    width: 40%;
}

.teklif-box-content {
    width: 60%;
}

.teklif-image {
    width: 100%;
}

.active {
    display: block;
}

.inactive {
    display: none;
}

.kasko-adim-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1150px;
}
.isActive {
    padding: 5px 5px;
    border-radius: 50px;
    margin: 15px 5px;
}

.inActive {
    padding: 5px 5px;
    border-radius: 50px;
    margin: 15px 5px;
}
.inActive .kasko-adim-text {
    color: rgba(90, 110, 126, .3);
}
.kasko-adim-text {
    font-size: 17px;
    color: #5A6E7E;
    letter-spacing: 1px;
    font-weight: 500;
}

.kasko-bilgi-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
}

.text-input {
    width: 360px !important;
    height: 55px;
    margin-bottom: 20px !important;
    border-radius: 50px !important;
}


.onay-button {
    background-color: #0A4CAC !important;
    border-radius: 15px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.onay-button {
    text-decoration: none;
    color: #fff;
}

.geri-button {
    width: 100px !important;
    background-color: #c2272d !important;
    margin-bottom: 20px !important;
}

.sepet-fixed-button {
    width: 100px !important;
    background-color: #0A4CAC !important;
    margin-bottom: 20px !important;
    border-radius: 15px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

#adim3 .text-input-adim {
    width: 170px;
    margin-bottom: 20px;
    border-radius: 50px;
    height: 55px;
}

.RuhsatImage {
    width: 110px;
}
#adim5 p,
#adim5 h5 {
    margin-bottom: 0px !important;
}

/*--------------------------------------- Kasko Teklif Container ---------------------------------------*/

.kasko-teklif-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
}

.EnUygun {
    background-color: #cddaee;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: 55px;
    border-radius: 15px;
    width: 100%;
}

.EnUygun label {
    font-weight: bold;
    font-size: 14px;
}

.en-uygun-line {
    background-color: #fff;
    width: 2px;
    height: 90%;
}

.TeklifContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 50px;
}

.TeklifListesiContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    min-height: 250px;
}

.kasko-teklif-container .SepetContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 25px;
}

.SepetContainer .teklif-listesi-a {
    font-size: 13px;
    color: #000;
}

.SepetContainer .teklif-box {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border: 1px solid #5A6E7E;
    border-radius: 15px;
    width: 250px;
    height: 348px;
    margin: 0 5px 10px;
}

.sepet-button{
    color: #fff;
    padding: 5px 10px;
    font-size: 13px;
    border-radius: 50px;
}

input[type="date"] {
    text-transform: uppercase;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

.satin-al{
    background-color: #0A4CAC;
}

.sizi-arayalim {
    background-color: #5A6E7E;
}

.TeklifListesiContainer .teklif-listesi-a {
    font-size: 13px;
    color: #000;
}

.teklif-ekle-button {
    background-color: #5A6E7E;
    color: #fff;
    padding: 5px 30px;
    font-size: 13px;
    border-radius: 50px;
    margin-bottom: 15px;
}

.teklif-ekle-button:hover{
    color: #fff;
}

.teklif-satinal-button {
    background-color: #0A4CAC;
    color: #fff;
    padding: 5px 10px;
    font-size: 13px;
    border-radius: 50px;
    margin-bottom: 15px;
}
.teklif-box {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border: 1px solid #5A6E7E;
    border-radius: 15px;
    width: 250px;
    height: 338px;
    margin: 0 5px 10px;
    padding-top: 20px;
}

.teklif-box-image {
    height: 41px; 
}
.en-uygun-teklif-box{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: #CDDAEE;
    border-radius: 15px;
    width: 250px;
    height: 338px;
    margin: 0 5px 10px;
    padding-top: 20px;
}

.en-uygun-teklif-box .yildizli {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #FF0000;
    padding: 10px;
    width: 50px;
    height: 50px;
    border-radius: 100px;
    position: absolute;
    transform: rotate(-15deg);
    -webkit-transform: rotate(-15deg);
    margin-bottom: 290px;
    margin-right: 230px;
}

.en-uygun-teklif-box .yildizli p {
    margin-bottom: 0px;
    font-size: 10px;
    color: #fff;
    font-weight: bold;
}
.teklif-box label,
.en-uygun-teklif-box label {
    font-size: 13px;
}

.teklif-box-line{
    width: 100%;
    height: 1px;
    background-color: #707070;
    margin: 15px 0;
}

.sepet-container {
    background-color: #0A4CAC;
    border-radius: 15px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
}

.teklif-karsilastirma-container{
    background-color: #e5e5e5;
    border-radius: 15px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.geri-button {
    background-color: #c2272d;
    border-radius: 15px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.sepet-container .teklif-karsilastirma-box,
.teklif-karsilastirma-container .teklif-karsilastirma-box {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    padding: 5px 3px;
    width: 300px;
    margin-bottom: 5px;
}

.teklif-karsilastirma-box .teklif-karsilastirma-line {
    background-color: #707070;
    width: 1px;
    height: 30px;
    margin: 0 10px;
}

.teklif-karsilastirma-box p {
    margin-bottom: 0;
    font-size: 10px;
}

.teklif-karsilastirma-container .teklif-karsilastir-button{
    color: #fff;
    font-size: 15px;
    background-color: #0A4CAC;
    width: 100%;
    padding: 5px;
    border-radius: 50px;
    margin-top: 10px;
    text-align: center;
}

.sepet-container h4 {
    color: #fff;
}

.sepet-button {
    color: #fff;
    font-size: 15px;
    border: 2px solid #fff;
    width: 100%;
    padding: 5px;
    border-radius: 10px;
    margin-top: 10px;
    text-align: center;
}

.teklif-input-box {
    width: 360px;
    text-align: center;
    margin-bottom: 10px;
}

.teklif-input {
    border: 1px solid #707070;
    width: 100%;
    height: 43px;
    border-radius: 50px;
    padding: 10px;
    margin-bottom: 5px;
}


.en-uygun-teklif-box a,
.teklif-box a{
    text-decoration: none;
}

.teklif-adet {
    width: 200px; 
    padding: 0 25px; 
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.teklif-fiyat {
    width: 500px; 
    padding: 0 25px; 
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.fixed-box {
    display: none;
}

.check-box {
    display: flex ;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 370px !important;
}

.form-check {
    font-size: 11px !important;
}

.input-group {
    width: 400px !important;
}

.input-group-text {
    margin-left: 0;
}

.text-p {
    width: 360px;
    text-align: center;
    font-size: 11px;
}

h5 {
    margin-bottom: 0px !important;
}

.ruhsat-box {
    width: 370px;
    display: flex;
    flex-direction: row;
    justify-content: space-between
}
.wrapper-loading {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ruhsat-box .wrapper {
    width: 195px;
    margin-bottom: 0;
}

.fixed-box {
    display: none;
}

.radio-line{
    display: none;
}

.ReactModal__Overlay {
    position: fixed;
    inset: 0px;
    background-color: rgba(255, 255, 255, 0.3) !important;
    backdrop-filter: blur(4px) !important;
    z-index: 11;
}

.success-modal-box,
.gizlilik-modal-box,
.odeme-modal-box,
.sepet-modal-box,
.karsilastirma-modal-box {
    position: absolute;
    inset: 40px;
    border: none !important;
    background: none;
    overflow: auto;
    border-radius: 4px;
    outline: none;
}
.karsilastir-modal-container {
    background-color: rgba(181, 181, 181, 0.9);
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 80px;
    backdrop-filter: blur(3px);
}

.odeme-modal-container {
    background-color: rgba(90, 110, 126, 0.5);
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 80px;
    backdrop-filter: blur(3px);
}

.hata-modal-container {
    background-color: white;
    border-radius: 0px;
    border: 1px solid #707070;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    backdrop-filter: blur(3px);
}

.hata-modal-button {
    width: 10px;
    height: 10px;
}

.police-modal-container {
    background-color: rgba(90, 110, 126, 0.5);
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 40px;
    backdrop-filter: blur(3px);
}

.gizlilik-modal-container {
    background-color: rgba(230, 230, 230, 0.9);
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 80px;
    width: 80%;
    margin: 0px 100px;

}

.succes-modal-container {
    background-color: rgba(230, 230, 230, 1);
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 80px;
    backdrop-filter: blur(3px);
}

.karsilastir-modal-content {
    border: 3px solid #fff;
    border-radius: 10px;
    width: 1000px;
    margin-top: 15px;
    padding: 10px;
}
.yukleniyor-modal-box {
    position: absolute;
    text-align: center;
    inset: 40px;
    border: none !important;
    background: none;
    overflow: auto;
    outline: none;
}
#spinnerKasko img,
#spinnerTrafik img,
#spinner img {
    height: 35px;
    margin-right: 20px;
}

#yukleniyor img {
    height: 70px;
}

#yukleniyorLabel {
    margin-top: 5px;
    font-size: 13px;
}

.karsilastir-container {
    overflow-y: scroll;
    height: 550px;
}

.gizlilik-container {
    overflow-y: scroll;
    height: 500px;
    margin-bottom: 30px;
    padding: 12px;
    font-weight: 400;
}

.gizlilik-container p {
    font-size: 13px;
}

.karsilastir-teminat-box {
    padding: 0 30px;
}

.modalImg {
    height: 45px;
}

.sirket-teminat-box ul,
.karsilastir-teminat-box ul {
    padding-left: 0px;
}

.sirket-teminat-box ul li,
.karsilastir-teminat-box ul li {
    height: 35px;
    font-size: 12px;
}

.sirket-teminat-box {
    width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 0;
    height: 143%;
    border-left:  1px solid #fff;
}

.sirket-teminat-image {
    height: 30px;
}

.teminat-button {
    width: 75px;
    height: 25px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-color: #0A4CAC;
    border-radius: 50px;
    cursor: pointer;
}

.onay-icon {
    height: 25px;
}

.odeme-modal-line {
    width: 100%;
    height: 2px;
    background-color: #fff;
    margin: 20px 0;
}

.odeme-ozet{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.taksit-box-container {
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.taksit-box {
    height: 43px;
    width: 200px;
    background-color: #fff;
    border-radius: 50px;
    border: 1px solid #5A6E7E;
    padding-left: 10px;
}

.cart-box-container {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.cart-box {
    height: 43px;
    width: 350px;
    background-color: #fff;
    border-radius: 50px;
    border: 1px solid #5A6E7E;
    padding-left: 10px;
    text-align: center;
}

.cart-box-input {
    height: 43px;
    width: 160px;
    background-color: #fff;
    border-radius: 50px;
    border: 1px solid #5A6E7E;
    padding-left: 10px;
    margin-right: 5px;
    color: #757575;
    text-align: center;
}

.cart-box-input2 {
    height: 43px;
    width: 100px;
    background-color: #fff;
    border-radius: 50px;
    border: 1px solid #5A6E7E;
    padding-left: 10px;
    margin-right: 5px;
    color: #757575;
    text-align: center;
}

.credit-cart-box {
    height: 230px;
    width: 360px;
    border-radius: 30px;
    background-color: #fff;
    margin-left: 20px;
}

.odeme-radio-box .form-check-label{
 font-size: 12px;
}

.odeme-button {
    border: 1px solid #fff;
    border-radius: 50px;
    height: 43px;
    width: 200px;
    background-color: #0A4CAC;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}

.odeme-button a {
    color: #fff;
    text-decoration: none;
    font-size: 18px;
}
.odeme-bilgi-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.odeme-box-container {
    width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.cart-box-group {
    width: 350px;
}

@media screen and (max-width: 950px) {
    .odeme-bilgi-container {
        display: flex;
        justify-content: center !important;
        align-items: center;
        flex-wrap: wrap;
    }
    
    .credit-cart-box {
        display: none;
    }

    .teklif-image-content {
        display: none;
    } 
    
    .teklif-box-content {
        width: 100%;
        margin: 60px 0;
    }
}

@media screen and (max-width: 450px) {
    .kasko-banner  {
        display: none;
    }
    .kasko-adim-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 500px;
    }
    .isActive {
        padding: 5px 10px!important;
        margin: 15px 5px;
    }
    
    .inActive {
        padding: 5px 10px !important;
        margin: 15px 5px;
    }
    
    .teklif-image-content {
        display: none;
    } 

    .teklif-box-content {
        width: 100%;
        margin: 0px 0;
    }

    .kasko-adim-container .kasko-adim-text {
        font-size: 12px !important;
        font-weight: 600;
    }
    .TeklifContainer .daha-cok,
    .en-uygun-teklif-box .karsilastirma,
    .teklif-box .karsilastirma,
    .sepet{
        display: none !important;
    }

    .teklif-box-image {
        margin-top: 10px;
    }

    .teklif-box-line {
        margin: 0px;
    }

    .EnUygun {
        height: 100px;
    }

    .EnUygun svg {
        width: 60px !important;
        height: 60px !important;
    }

    .EnUygun .mobile-line {
        display: none;
    }

    .teklif-adet {
        width: 200px; 
        padding: 0 5px; 
        flex-wrap: wrap;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    
    .teklif-fiyat {
        width: 330px; 
        padding: 0 15px; 
        flex-wrap: wrap;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .fixed-box{
        position: fixed;
        bottom: 10px;
        left: 10px;
        right: 10px;
        background-color: rgba(205, 218, 238, .7);
        padding: 15px 10px 0px;
        border-radius: 22px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .fixed-box a{
        text-decoration: none;
        color: #fff !important;
    }

    .seyahat-container {
        padding: 10px 0 30px !important;
     }

    .TeklifContainer {
        margin-top: 30px;
    }

    .check-box {
        width: 330px !important;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .form-check-label {
        font-size: 10px;
    }

    .text-p {
        width: 330px;
        font-size: 15px;
        text-align: center;
    }

    .kasko-bilgi-container {
        padding: 5px 0px;
    }

    .input-group {
        width: 350px !important;
        margin-left: 0px;
    }
    
    .RuhsatImage {
        width: 130px;
    }
    .ruhsat-box {
        width: 350px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .ruhsat-box .wrapper {
        width: 165px;
        margin-bottom: 0;
    }

    .radio-box-adim7 {
        margin-bottom: 0 !important;
    } 

    .radio-line{
        display: block;
        width: 100%;
        height: 2px;
        background-color: #5A6E7E;
        margin: 15px 0;
    }

    .kasko-teklif-container {
        padding: 10px 0px 50px;
    }

    .credit-cart-box {
        display: none;
    }

    .cart-box-input,
    .cart-box,
    .taksit-box {
        height: 35px;
    }
    .odeme-modal-line {
        margin: 5px 0;
    }
    .odeme-radio-box .form-check-label {
        font-size: 9px;
    }
    .odeme-button {
        margin-top: 10px;
        height: 35px;
    }
    .odeme-button a {
        font-size: 15px;
    }
    .odeme-modal-container {
        padding: 74px 1px;
        border-radius: 0px;
    }

    .cart-box {
        width: 330px;
    }

    .cart-box-group {
        width: 330px;
    }

    .cart-box-container {
        margin-top: 0;
        margin: 30px;
    }
}


.form-check-input {
    width: 1.5em;
    height: 1.5em;
    margin-right: 1em;
}

/*------------------------------ Seyahat Sigortası ------------------------------*/

.diger-banner {
    background-image: url('../../images/diger.jpg');
    height: 270px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px 0;
}

.seyahat-banner {
    background-image: url('../../images/kaskobg.png');
    height: 270px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px 0;
}

.seyahat-banner-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.seyahat-banner-content h1 {
    color: #fff;
}

.active {
    display: block;
}

.inactive {
    display: none;
}

.seyahat-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px 0;
}


.wrapper input {
    background-color: #fff;
    width: 100%;
    height: 100%;
    border: none;
    border: 1px solid #5a6e7e;
    border-radius: 50px;
    padding-left: 20px;
    color: #5a6e7e;
    font-size: 15px;
}


.wrapper input:focus + label,
.wrapper input:valid + label {
    font-size: 13px;
    top: 0;
    background-color: #fff;
    padding: 5px;
    color: #1890ff;
    
}

.wrappertextarea {
    width: 370px;
    height: 120px;
    position: relative;
    margin-bottom: 20px;
}

.wrappertextarea label {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    transition: all .8s ease;
    font-size: 15px;
    pointer-events: none;
    color: #5a6e7e !important;
    background-color: #fff;
    padding-right: 20px;
    font-size: 15px;
}

.wrappertextarea textarea {
    background-color: #fff;
    width: 100%;
    height: 100%;
    border: none;
    border: 1px solid #5a6e7e;
    padding-left: 20px;
    padding-top: 10px;
    color: #5a6e7e;
    font-size: 13px;
    min-height: 120px;
}

.wrappertextarea textarea:focus + label,
.wrappertextarea textarea:valid + label {
    font-size: 13px;
    top: 0;
    background-color: #fff;
    padding: 5px;
    color: #1890ff;
    
}

.wrapper button {
    background-color: #0A4CAC;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    color: #fff;
    font-size: 20px;
    margin-top: 20px;
    border: none;
}

.wrapper .g-button {
    background-color: #c2272d ;
    width: 20%;
    height: 100%;
    border-radius: 50px;
    color: #fff;
    font-size: 20px;
    margin-top: 20px;
    border: none;
}

.wrapper .o-button {
    background-color: #0A4CAC;
    width: 75%;
    height: 100%;
    border-radius: 50px;
    color: #fff;
    font-size: 20px;
    margin-top: 20px;
    border: none;
    margin-left: 5%;
}

.sirketler{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 350px;
}

.sirketler .SirketImage {
    height: 35px;
    margin: 0 50px;
}

.title-konut {
    text-align: center;
    margin-bottom: 70px;
}

.title-konut h2 {
    font-size: 36px;
    font-weight: 300;
}

.wrapper div {
    /* background-color: #0A4CAC; */
    width: 100%;
    height: 100%;
    border-radius: 50px;
    color: #fff;
    font-size: 22px;
    margin-top: 20px;
    border: none;
    padding: 8px 9px;
}

.radio-box .form-check {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}

.radio-box-adim7{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 150px;
    margin-bottom: 15px;
}


.radio-box .form-check label {
    font-size: 20px;
    margin-left: 10px;
}

.radio-box-adim7 .form-check  {
    margin-top: 10px;
}

.radio-box-adim7 .form-check label{
    font-size: 15px;
    margin-left: 5px;
    font-weight: 500;
}

.input-group {
    width: 370px !important;
}
/*---------------- Trafik Sigortası ------------------------ */
.input-group-text {
    border-top-left-radius: 30px !important;
    border-bottom-left-radius: 30px !important;
    background-color: #0a4cac !important;
    color: #fff !important;
    border: 1px solid #5a6e7e !important;
}

/*--------------------------- Dask Sigortası ----------------------- */

.bilgi-box {
    width: 360px;
    padding: 10px 5px;
    border-radius: 15px;
    border: 1px solid #5a6e7e;
}

.dask-bilgi-box {
    width: 700px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.konut-button{

    margin-top: 20px;
}
.konut-button a {
    color: black;
}

/* ------------------------------------ Konut Sigortası --------------------------------- */

.active {
    display: block;
}

.inactive {
    display: none;
}

.teklif-form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
}

.konut-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
}

.konut-adim2-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 50px 0;
}

.wrapper select {
    background-color: #fff;
    width: 100%;
    height: 100%;
    border: none;
    border: 1px solid #5a6e7e;
    border-radius: 50px;
    padding-left: 20px;
    color: #5a6e7e;
}


.wrapper select:focus + label,
.wrapper select:valid + label {
    font-size: 15px;
    top: 0;
    background-color: #fff;
    padding: 5px;
    color: #1890ff;
    
}

.sirketler{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 350px;
}

.sirketler .SirketImage {
    height: 35px;
    margin: 0 50px;
}

.title-konut {
    text-align: center;
    margin-bottom: 70px;
}

.title-konut h2 {
    font-size: 36px;
    font-weight: 300;
}

.wrapper div {
    width: 100%;
    height: 100%;
    border-radius: 50px;
    color: #fff;
    font-size: 22px;
    margin-top: 20px;
    border: none;
    padding: 8px 9px;
}

/* ----------------------- Teklif Form ------------------------*/

.active {
    display: block;
}

.inactive {
    display: none;
}

.konut-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
}

.konut-adim2-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 50px 0;
}

.input-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dask-bilgi-box .wrapper {
    width: 340px;
}

.wrapper {
    width: 370px;
    height: 40px;
    position: relative;
    margin-bottom: 20px;
}

.wrapper select {
    background-color: #fff;
    width: 100%;
    height: 100%;
    border: none;
    border: 1px solid #5a6e7e;
    border-radius: 50px;
    padding-left: 20px;
    color: #5a6e7e;
    font-size: 15px;
}

.wrapper label {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    transition: all .8s ease;
    font-size: 15px;
    pointer-events: none;
    color: #5a6e7e !important;
    background-color: #fff;
    padding-right: 20px;
    font-size: 15px;
}

.sirketler{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 350px;
}

.sirketler .SirketImage {
    height: 35px;
    margin: 0 50px;
}

.title-konut {
    text-align: center;
    margin-bottom: 70px;
}

.title-konut h2 {
    font-size: 36px;
    font-weight: 300;
}

.wrapper div {
    width: 100%;
    height: 100%;
    border-radius: 50px;
    color: #fff;
    font-size: 22px;
    margin-top: 20px;
    border: none;
    padding: 8px 9px;
}

.konut-container {
    text-align: center;
}

.geri {
   place-self: flex-start;
   color: #000;
   text-decoration: aut;
}

.geri a {
   text-decoration: none;
}

@media screen and (max-width: 450px) {
    .wrapper {
        width: 350px;
        font-size: 12px;
        height: 40px;
    }
    .wrapper label {
        font-size: 12px;
    }

    .wrapper .o-button,
    .wrapper .g-button {
        margin-top: 0px !important;
    }
   
}