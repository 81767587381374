.Hakkimizda {
    background-image: url('../../images/bg.png');
    height: 150px;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

.HakkimizdaTitle {
    width: 50%;
}

@media screen and (max-width: 450px) {
    .HakkimizdaTitle {
        width: 60%;
    }
}