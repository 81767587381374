.TeklifKarsilastir {
    padding: 50px 0;
 }

 .TeklifKarsilastirBox {
     display: flex;
     justify-content: center;
     align-items: center;
     flex-wrap: wrap;
 }

 .KampanyaContent h3 {
     margin-bottom: 0;
 }
