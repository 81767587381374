.sticky {
  position: sticky;
  top: 0;
  z-index: 10;
}

  header {
    background-color: #fff;
    height: 84px; 
    border-bottom: 3px solid #5a6e7e;
  }

  header .logo img {
    height: 50px;
    margin: 15px 0;
  }

  header .logo a {
    border-left: 0;
  }

  header ul {
    margin-bottom: 0;
  }

  header ul li {
    border-left: 1px solid #5a6e7e;
    padding: 19px 25px 23px;
    
  }  
  .nav-link {
    color: #000 !important;
  }

  .dropdown-menu {
    transform: translate(-26px, 59px) !important;
    background-color: #5a6e7e !important;
  }

  .dropdown-menu a {
    color: #fff !important;
    font-weight: 300;
  }

  .dropdown-menu a:hover {
    background: none !important;
    text-decoration: underline;
  }

  .dropdown-toggle::after {
    display: none !important;
  }

  header a {
    transition: 0.5s;
    font-weight: 500;
    text-decoration: none;
    color: #000;
  }

  header a:hover {
    color: #5a6e7e;
  }
  header span {
    width: auto;
    padding: 0px 6px;
    background: #27ae60;
    border-radius: 50px;
    color: #ffffff;
    margin-right: 4px;
    font-weight: 400;
  }
  header h4 {
    font-weight: 500;
    margin-right: 20px;
  }
  header .toggle {
    display: none;
  }
  header .toggle img {
    margin-top: 0;
  }

  header .toggle ul {
    padding-left: 0!important;
  }

  .navbar {
    width: 90px;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  
  .navbar img {
    height: 40px;
  }

  .menu-bars {
    font-size: 2rem;
    background: none;
  }
  
  .nav-menu {
    background-color: #5a6e7e;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    right: -100%;
    transition: 850ms;
  }
  
  .nav-menu.active {
    right: 0;
    transition: 350ms;
    padding-left: 15px;
    padding-top: 20px;
    height: 100vh;
  }
  
  .nav-text {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 60px;
  }
  
  .nav-text a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
  }
  
  .nav-text a:hover {
    background-color: #1a83ff;
  }
  
  .nav-menu-items {
    width: 100%;
  }
  
  .navbar-toggle {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  
  span {
    margin-left: 16px;
  }

  .modal{
    background-color: rgba(255, 255, 255, 0.1) !important;
    backdrop-filter: blur(3px) !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .modal-box {
    width: 450px !important;
    background-color: #5a6e7e;
    border-radius: 40px;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 40px;
  }

  .w-330 {
    width: 330px !important;
  }

  

  .close-button {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
    cursor: pointer;
    align-self: stretch;
  }

  .close-icon {
    width: 20px;
    height: 20px;
  }

  .modal-box h2 {
    color: #f5f5f5 ;
  }

  .input-box2 {
    height: 45px;
    display: flex; 
    flex-direction: column;
    justify-content: space-between;
    /* margin: 20px 0; */
  }

  .input-box2 button {
    text-align: right;
    width: 75%;
  }

 .profil-bilgi-box .input-box2 {
   border: 1px solid darkgray !important;
}

  .input-box-register {
    height: 90px; 
    width: 100% !important;
    display: flex; 
    flex-direction: column;
    justify-content: space-between;
    margin: 20px 0;
  }

  .modal-input {
    border-radius: 15px !important;
    text-align: center !important;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .modal-span {
    position: absolute;
    padding: 3px 14px;
    border: 0;
    background: 0;
  }

  .modal-input input {
    border-radius: 15px !important;
    text-align: center !important;
    text-transform: lowercase;
  }

  #pass-inp {
    text-transform: none;
  }

  .robot-img {
    height: 50px;
    margin: 15px 0;
  }

  .link-content {
    height: 30px;
  }

  .link-divider {
    border-right: 1px solid rgb(247 246 246);
  }

  .link-right {
    padding-top: 20px;
    align-self: flex-start;
  }

  .link-button {
    height: 40px;
    width: 170px;
    background-color: transparent !important;
    border-radius: 15px !important;
    border: 2px solid #fff !important;
    margin-top: 20px !important;
    margin-bottom: 10px;
  }

  .LinkButtonImage {
    height: 40px;
    margin-left: 10px;
    margin-bottom: 25px;
}

  .modal-button {
    height: 60px;
    background-color: #0A4CAC !important;
    border-radius: 15px !important;
    border: 1px solid #fff !important;
    margin-top: 20px !important;
  }

  .modal-link{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
  }

  .modal-link-register {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
  }

  .modal-link-register a,
  .modal-link a {
    color: #fff;
    font-weight: 300;
  }
  
  .modal-link-register a:hover,
  .modal-link a:hover {
    color: #fff;
   text-decoration: underline;
  }

  .modal-link-register .uye-ol,
  .modal-link .uye-ol {
    font-weight: 600;
    font-size: 18px;
  }

  .text-hesabim a {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  .text-hesabim a img {
    height: 25px;
    margin-left: 5px;
  }

  .text-hesabim,
  .text {
    padding: 10px 25px;
  }
  .text-hesabim a,
  .text a {
    color: #fff;
  }
  .text-hesabim a:hover,
  .text a:hover {
    color: #fff;
    letter-spacing: 1px;
  }

  .toggleButton {
    display: flex;
    flex-direction: row;
  }

  .toggleButton h6 {
    margin-top: 0;
    margin-bottom: 0rem !important; 
    font-weight: 500;
    line-height: 0 !important;
    padding: 10px 0;
    color: #fff;
    cursor: pointer;
  }

  .menuToggle {
    display: none;
  }

  .menuToggle .menuToggleContent {
    width: 100%;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }

  .menuToggleContent a {
    font-weight: 400;
    color: #fff;
    font-size: 12px;
    margin-bottom: 10px;
  }

  .form-group-feedback-right .form-control-feedback {
    right: 0;
}
.form-control-feedback {
    position: absolute;
    top: 0;
    color: #333;
    padding-left: 0.875rem;
    padding-right: 0.875rem;
    line-height: calc(1.5385em + 0.875rem + 2px);
    min-width: 1rem;
}

  @media screen and (max-width: 950px) {
    header {
      padding: 0;
    }
    header img {
      margin-top: 30px;
    }
    header .button {
      display: none;
    }
    header .nav {
      display: none;
    }
    header .toggle {
      display: flex;
      flex-direction: row;
    }
    .nav-line {
      height: 1px;
      background-color: #fff;
      margin-left: 22px;
    }
    header .small {
      position: absolute;
      top: 10vh;
      left: 0;
      width: 100%;
      background-color: #27ae60;
      padding: 20px;
    }
    header li a {
      display: inline-block;
      margin-bottom: 0px;
      color: #fff;
    }
    .modal-box {
      width: 400px;
    }
  }

  @media screen and (max-width: 450px) {

    header {
      height: 60px;
    }

    header .logo img {
      height: 30px;
      margin: 15px 0;
    }
    .menu-bars  {
      font-size: 0rem;
    }
    .navbar {
      width: 80px;
    }

    .navbar img {
      height: 34px;
  }
    .modal-box {
      width: 300px;
    }
    /* .w-330 {
      width: 250px !important;
    } */

    .w-200 {
      width: 200px !important;
    } 

    .modal-box {
      width: 100% !important;
      background-color: #5a6e7e;
      border-radius: 50px;
      z-index: 9999;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 10px;
      /* height: 100%; */
    }

    .modal-span {
      /* margin-top: -9%; */
      /* margin-left: 51%; */
text-align: right;
    }
  }

  @media screen and (max-width: 300px) {
    
    header img {
      margin-top: 10px;
    }
    .logo {
      margin-right: 0 !important ;
    }
    header .nav {
      display: none;
    }
    header .toggle {
      display: flex;
      flex-direction: row;
    }
    header .small {
      position: absolute;
      top: 10vh;
      left: 0;
      width: 100%;
      background-color: #27ae60;
      padding: 20px;
    }
    header li a {
      display: inline-block;
      margin-bottom: 20px;
      color: #fff;
    }
    .modal-box {
      width: 240px;
    }
    .navbar {
      width: 70px;
      height: 80px;
      display: flex;
      justify-content: start;
      align-items: center;
    }
    .w-330 {
      width: 200px !important;
    }
    .navbar img {
      height: 30px;
    }
  
  }