.konut-banner {
    background-image: url('../../images/konut.jpg');
    height: 270px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px 0;
}

.konut-banner-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.konut-banner-content h1 {
    color: #000;
}

@media screen and (max-width: 450px) {
    .sirketler {
        display: none;
    }
}