.CozumOrtaklari {
    margin: 30px 0;
}

.SirketImageBox {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.SirketImage {
    height: 50px;
    margin: 30px;
}

#sirket-mobil {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    transition: all 1s ease;
  }
  
  .slider-item {
    width: 220px;
    height: 110px;
    display: inline-block;
    margin: 20px;
    position: relative;
  }
  
  .slider-item img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 200px;
    max-height: 100px;
  }
  
  .sirket-mobil {
    display: none;
  }

@media screen and (max-width: 450px) {
    .SirketImageBox {
        display: none;
    }
    .sirket-mobil {
      display: block;
    }
}