.Kampanya {
    padding: 50px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.SlideImage {
    width: 100%;
    height: 300px;
    border-radius: 15px;
}

.KampanyaTitle {
    text-align: center;
    margin-bottom: 30px;
}

.KampanyaContent {
    height: 300px;
    padding-top: 10%;
}

.KampanyaSubTitle{
    font-weight: 600;
}

.KampanyaText{
    font-size: 13px;
    margin: 35px 0;
}

.KampanyaButton{
    text-decoration: none;
}

.slick-dots {
    width: 65% !important;
}

@media screen and (max-width: 1199px) {
    .slick-dots {
        display: none !important;
    }
}

@media screen and (max-width: 450px) {
    .SlideImage {
        height: 215px;
    }
}
