.contact-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    height: 100%;
    float: none;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    margin-bottom: 50px;
}

.contact-container .contact-info-box {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.contact-info-box {
    min-width: 400px;
}

.contact-box {
    width: 100%;
    padding: 25px;
    background-color: #eaeaea;
}

.contact-form-box {
    padding: 25px;
    background-color: #eaeaea;
    border: 0;
}

.contact-bilgi-box {
    border: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 15px;
    margin-top: 10px;
}

.contact-form-box .title-box {
    width: 150px;
}

.contact-form-box .text-box {
    width: 500px;
}

.contact-form-box button {
    width: 150px;
}

.info-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
}

.info-box-container .info-box {
    display: flex;
    flex-direction: row !important;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
}

.info-box-icon {
    height: 25px;
}

.info-box-title {
    font-weight: bold;
    width: 120px;
}

.company-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
}

.info-box-line {
    width: 2px;
    height: 100%;
    background-color: #000;
    margin: 0 10px;
}

.info-box-iletisim {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
}

.google-captcha {
    margin-top: 20px;
    text-align: -webkit-center;
}

.form-box {
    position: absolute;
    border: none !important;
    background: none;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: "translate(-50%, -50%)";
}

.hata-modal-container {
    background-color: white;
    border-radius: 0px;
    border: 1px solid #707070;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    backdrop-filter: blur(3px);

}

.hata-modal-button {
    width: 10px;
    height: 10px;
}